import React, {useState} from "react"
import {graphql} from "gatsby";

import {Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row} from 'reactstrap'
import Layout from "../components/layout"
import PageTitle from '../components/pageTitle'
import SEO from "../components/seo"
import {LangContext} from "../components/lurraldebusWrapper";

const FormRowClassName = "px-2 px-lg-5";

const QuejasYSugerenciasPage = ({data}) => {

  const [formStatus, setFormStatus] = useState(null);
  const [processing, setProcessing] = useState(false);

  const submitForm = (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const form = ev.target;
    const formdata = new FormData(form);
    const data = {
      name: formdata.get('nombre') + " " + formdata.get('apellidos'),
      email: formdata.get('email'),
      description: formdata.get('mensaje'),
      gdpr: formdata.get('gdpr') === 'on'
    }
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", btoa("ingartek:ingartek"));
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      } else {
        setProcessing(false)
      }
      if (xhr.status === 200) {
        form.reset();
        setFormStatus('ok');
      } else {
        setFormStatus('ko');
      }
    };
    xhr.send(JSON.stringify(data));
  }

  return (
    <LangContext.Consumer>
      { context => (
        <Layout>
          <SEO title={context.translate(data, 'quejasYSugerencias', 'titulo')} />
          <Container className="py-5 mb-5">
            <PageTitle title={context.translate(data, 'quejasYSugerencias', 'titulo')}/>

            <Form className="px-2 px-lg-5 pt-5"
                  onSubmit={submitForm}
                  action={context.translate(data, 'quejasYSugerencias', 'formulario').action}
                  method="POST">
              <Input type="hidden" name="tipo" id="tipo" value={context.translate(data, 'quejasYSugerencias', 'formulario').tipo} required/>

              <Row form className={FormRowClassName}>
                <Col xs={12} lg={6}>
                  <FormGroup>
                    <Input type="text" name="nombre" id="nombre" placeholder={context.translate(data, 'quejasYSugerencias', 'formulario').nombre} required/>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={6}>
                  <FormGroup>
                    <Input type="text" name="apellidos" id="apellidos" placeholder={context.translate(data, 'quejasYSugerencias', 'formulario').apellidos} required/>
                  </FormGroup>
                </Col>
              </Row>

              <Row form className={FormRowClassName}>
                <Col xs={12} lg={12}>
                  <FormGroup>
                    <Input type="email" name="email" id="email" placeholder={context.translate(data, 'quejasYSugerencias', 'formulario').email} required/>
                  </FormGroup>
                </Col>
              </Row>

              <Row form className={FormRowClassName}>
                <Col xs={12}>
                  <FormGroup>
                    <Input type="textarea" name="mensaje" id="mensaje" placeholder={context.translate(data, 'quejasYSugerencias', 'formulario').mensaje} rows={8} required/>
                  </FormGroup>
                </Col>
              </Row>

              <Row form className={FormRowClassName}>
                <Col xs={12} lg={6}>
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox" name="gdpr" id="gdpr" required/>
                      {
                        context.activeLang === "eus"
                          ? (<a href={"https://gipuzkoa.zendesk.com/hc/es/articles/360001156025-GPDR"}>Datuak Babesteko Erregelamendu Orokorra Onartzen Dut</a>)
                          : (<a href={"https://gipuzkoa.zendesk.com/hc/es/articles/360001156025-GPDR"}>Acepto el Reglamento General de Protección de Datos</a>)
                      }
                    </Label>
                  </FormGroup>
                </Col>
              </Row>

              <Row form className={`${FormRowClassName} pt-4`}>
                <Col xs={12}>
                  <FormGroup className="text-center">
                    {formStatus === null ? (
                      <Button color="success" className="px-5" disabled={processing}>
                        {!processing ? context.translate(data, 'quejasYSugerencias', 'formulario').enviar : context.translate(data, 'quejasYSugerencias', 'formulario').enviando}
                      </Button>) :
                      formStatus === 'ok' ?
                        (<Alert color="success">{context.translate(data, 'quejasYSugerencias', 'formulario').mensajeOk}</Alert>) :
                        (<Alert color="danger">{context.translate(data, 'quejasYSugerencias', 'formulario').mensajeKo}</Alert>) }
                  </FormGroup>
                </Col>
              </Row>

            </Form>

          </Container>
        </Layout>
      )}
    </LangContext.Consumer>
  )
}

export default QuejasYSugerenciasPage

export const pageQuery = graphql`  
  query {
    quejasYSugerencias: strapiQuejasYSugerencias {
      titulo
      formulario {
        action
        apellidos
        cp
        direccion
        email
        enviando
        enviar
        fecha
        hora
        mensaje
        mensajeKo
        mensajeOk
        municipio
        nombre
        pais
        telefono
        tipo
      }
    }
    quejasYSugerencias_eus: strapiQuejasYSugerenciasEus {
      titulo
      formulario {
        action
        apellidos
        cp
        direccion
        email
        enviando
        enviar
        fecha
        hora
        mensaje
        mensajeKo
        mensajeOk
        municipio
        nombre
        pais
        telefono
        tipo
      }
    }
    lineas: allStrapiLineas {
      edges {
        node {
          label
          nombre
          rutas {
            direccion
            nombre
            paradas {
              nombre
              label
            }
          }
        }
      }
    }
  }
`
